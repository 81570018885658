import React, { useContext } from "react";
import { client } from "../apolloClient";
import {
  EndSessionDocument,
  EndSessionMutation,
  EndSessionMutationVariables,
  LoadSessionDocument,
  LoadSessionQuery,
  LoadSessionQueryVariables,
  SessionFragmentFragment,
  StartSessionDocument,
  StartSessionMutation,
  StartSessionMutationVariables,
} from "../generated/graphql";

export const SessionContext = React.createContext<
  SessionFragmentFragment | undefined
>(undefined);

export function useSession() {
  return useContext(SessionContext);
}

export async function startSession(tan: string) {
  console.log("Starting session");
  const rs = await client.mutate<
    StartSessionMutation,
    StartSessionMutationVariables
  >({
    mutation: StartSessionDocument,
    variables: { tan },
  });
  return rs;
}

export async function loadSession(token: string) {
  console.log("Loading session");
  const rs = await client.query<LoadSessionQuery, LoadSessionQueryVariables>({
    query: LoadSessionDocument,
    variables: { token },
  });
  return rs;
}

export async function endSession(token: string) {
  console.log("Ending session");
  await client.mutate<EndSessionMutation, EndSessionMutationVariables>({
    mutation: EndSessionDocument,
    variables: { token },
  });
}
