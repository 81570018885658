import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};

export type AccessCode = {
  __typename?: 'AccessCode';
  batch: AccessCodeBatch;
  id: Scalars['ID'];
  raffleTicketIssued: Scalars['Boolean'];
  session?: Maybe<AnswerSession>;
  tan: Scalars['String'];
};

export type AccessCodeBatch = {
  __typename?: 'AccessCodeBatch';
  accessCodes: Array<AccessCode>;
  codesClosed: Scalars['Int'];
  codesUsed: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  totalCodes: Scalars['Int'];
};

export type AllEventMetadataItem = {
  __typename?: 'AllEventMetadataItem';
  label: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  question: Question;
  session: AnswerSession;
  value?: Maybe<Scalars['String']>;
};

export type AnswerSession = {
  __typename?: 'AnswerSession';
  accessCode: AccessCode;
  answers: Array<Answer>;
  closedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  event: Event;
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  eventTypes: Array<EventType>;
  id: Scalars['ID'];
  image: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  questionTypes: Array<QuestionType>;
  questionnaires: Array<Questionnaire>;
  users: Array<User>;
};

export type Evaluation = {
  __typename?: 'Evaluation';
  endedSessions: Scalars['Int'];
  questions: Array<QuestionEvaluation>;
  startedSessions: Scalars['Int'];
  totalAnswers: Scalars['Int'];
};

export type EvaluationFilter = {
  answers?: InputMaybe<Array<EvaluationFilterQuestionAnswered>>;
};

export type EvaluationFilterQuestionAnswered = {
  answer?: InputMaybe<Scalars['String']>;
  question: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  accessCodeBatches: Array<AccessCodeBatch>;
  answerSessions: Array<AnswerSession>;
  createdAt: Scalars['DateTime'];
  eventGroup: EventGroup;
  eventType: EventType;
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  metadata: Array<EventMetadataItem>;
  name: Scalars['String'];
  raffle?: Maybe<Raffle>;
  statistics: EventStatistics;
  tan: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};

export type EventGroup = {
  __typename?: 'EventGroup';
  createdAt: Scalars['DateTime'];
  eventType: EventType;
  events: Array<Event>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EventGroupTemplate = {
  __typename?: 'EventGroupTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  templates: Array<EventGroupTemplateEventTemplate>;
};

export type EventGroupTemplateEventTemplate = {
  __typename?: 'EventGroupTemplateEventTemplate';
  day: Scalars['Int'];
  from: Scalars['String'];
  metadata: Array<EventMetadataItem>;
  name: Scalars['String'];
  to: Scalars['String'];
};

export type EventMetadataItem = {
  __typename?: 'EventMetadataItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type EventMetadataItemInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type EventStatistics = {
  __typename?: 'EventStatistics';
  sessions: Scalars['Int'];
  sessionsClosed: Scalars['Int'];
};

export type EventType = {
  __typename?: 'EventType';
  availableMetadata: Array<AllEventMetadataItem>;
  createdAt: Scalars['DateTime'];
  eventGroups: Array<EventGroup>;
  events: Array<Event>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questionnaire: Questionnaire;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  error?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<Scalars['Boolean']>;
  createEvent: Event;
  createEventGroup: EventGroup;
  createEventGroupTemplate: EventGroupTemplate;
  createEventType: EventType;
  createQuestion: Question;
  createQuestionType: QuestionType;
  createQuestionnaire: Questionnaire;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  deleteEventGroup?: Maybe<Scalars['Boolean']>;
  deleteEventGroupTemplate?: Maybe<Scalars['Boolean']>;
  deleteEventType?: Maybe<Scalars['Boolean']>;
  deleteQuestionType?: Maybe<Scalars['Boolean']>;
  deleteQuestionnaire: Scalars['Boolean'];
  endSession?: Maybe<Scalars['Boolean']>;
  enterRaffle?: Maybe<Scalars['String']>;
  generateAccessCodeBatch: AccessCodeBatch;
  login: LoginResult;
  startAnswerSessionWithAccessCode: AnswerSession;
  storeAnswer?: Maybe<Scalars['Boolean']>;
  storeComment?: Maybe<Scalars['Boolean']>;
  updateEvent?: Maybe<Event>;
  updateEventGroup?: Maybe<Scalars['Boolean']>;
  updateEventGroupTemplate?: Maybe<Scalars['Boolean']>;
  updateEventType?: Maybe<Scalars['Boolean']>;
  updateQuestion?: Maybe<Scalars['Boolean']>;
  updateQuestionType?: Maybe<Scalars['Boolean']>;
  updateQuestionnaire?: Maybe<Scalars['Boolean']>;
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateEventArgs = {
  eventGroupId: Scalars['ID'];
  metadata: Array<EventMetadataItemInput>;
  name: Scalars['String'];
};


export type MutationCreateEventGroupArgs = {
  eventTypeId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateEventGroupTemplateArgs = {
  name: Scalars['String'];
};


export type MutationCreateEventTypeArgs = {
  name: Scalars['String'];
  questionnaire: Scalars['ID'];
};


export type MutationCreateQuestionArgs = {
  nextCondition?: InputMaybe<Scalars['String']>;
  nextQuestionId?: InputMaybe<Scalars['ID']>;
  required: Scalars['Boolean'];
  text: Scalars['String'];
  typeId: Scalars['ID'];
};


export type MutationCreateQuestionTypeArgs = {
  name: Scalars['String'];
  options: Scalars['JSONObject'];
  style: QuestionStyle;
};


export type MutationCreateQuestionnaireArgs = {
  name: Scalars['String'];
  questions: Array<Scalars['ID']>;
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventGroupTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationEndSessionArgs = {
  token: Scalars['String'];
};


export type MutationEnterRaffleArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationGenerateAccessCodeBatchArgs = {
  amount: Scalars['Int'];
  eventId: Scalars['ID'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationStartAnswerSessionWithAccessCodeArgs = {
  tan: Scalars['String'];
};


export type MutationStoreAnswerArgs = {
  answer: Scalars['String'];
  questionId: Scalars['ID'];
  token: Scalars['String'];
};


export type MutationStoreCommentArgs = {
  comment: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  metadata: Array<EventMetadataItemInput>;
  name: Scalars['String'];
};


export type MutationUpdateEventGroupArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateEventGroupTemplateArgs = {
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateEventTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['ID'];
  nextCondition?: InputMaybe<Scalars['String']>;
  nextQuestionId?: InputMaybe<Scalars['ID']>;
  required: Scalars['Boolean'];
  text: Scalars['String'];
  type: Scalars['ID'];
};


export type MutationUpdateQuestionTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Scalars['JSONObject'];
  style: QuestionStyle;
};


export type MutationUpdateQuestionnaireArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  customer?: Maybe<Customer>;
  customers: Array<Customer>;
  evaluation: Evaluation;
  event?: Maybe<Event>;
  eventGroup?: Maybe<EventGroup>;
  eventGroupTemplate?: Maybe<EventGroupTemplate>;
  eventGroupTemplates: Array<EventGroupTemplate>;
  eventType?: Maybe<EventType>;
  eventTypes?: Maybe<Array<EventType>>;
  events?: Maybe<Array<Event>>;
  me: User;
  questionType?: Maybe<QuestionType>;
  questionTypes?: Maybe<Array<QuestionType>>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaires?: Maybe<Array<Questionnaire>>;
  raffles?: Maybe<Array<Raffle>>;
  session?: Maybe<AnswerSession>;
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEvaluationArgs = {
  eventType: Scalars['ID'];
  eventsToInclude: Array<Scalars['ID']>;
  filter?: InputMaybe<EvaluationFilter>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventGroupArgs = {
  id: Scalars['ID'];
};


export type QueryEventGroupTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEventGroupTemplatesArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventTypeArgs = {
  id: Scalars['ID'];
};


export type QueryEventTypesArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventsArgs = {
  eventGroupId: Scalars['ID'];
};


export type QueryQuestionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryRafflesArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QuerySessionArgs = {
  token: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  nextCondition?: Maybe<QuestionNextCondition>;
  required?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  type: QuestionType;
};

export type QuestionEvaluation = {
  __typename?: 'QuestionEvaluation';
  plotly: Scalars['JSON'];
  question: Question;
  results: Array<QuestionResult>;
};

export type QuestionNextCondition = {
  __typename?: 'QuestionNextCondition';
  condition: Scalars['String'];
  nextQuestion: Scalars['ID'];
};

export type QuestionResult = {
  __typename?: 'QuestionResult';
  absolute: Scalars['Int'];
  answer: Scalars['String'];
};

export enum QuestionStyle {
  Dropdown = 'DROPDOWN',
  Float = 'FLOAT',
  Int = 'INT',
  Longtext = 'LONGTEXT',
  Radio = 'RADIO',
  Shorttext = 'SHORTTEXT',
  Slider = 'SLIDER'
}

export type QuestionType = {
  __typename?: 'QuestionType';
  id: Scalars['ID'];
  isInUse: Scalars['Boolean'];
  name: Scalars['String'];
  options: Scalars['JSONObject'];
  style: QuestionStyle;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  eventTypes: Array<EventType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  official: Scalars['Boolean'];
  questions: Array<Question>;
};

export type Raffle = {
  __typename?: 'Raffle';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  participants: Scalars['Int'];
  prices: Array<RafflePrice>;
  winnersDetermined: Scalars['Boolean'];
};

export type RafflePrice = {
  __typename?: 'RafflePrice';
  id: Scalars['ID'];
  name: Scalars['String'];
  raffle: Raffle;
  winningTicket?: Maybe<RaffleTicket>;
};

export type RaffleTicket = {
  __typename?: 'RaffleTicket';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  raffle: Raffle;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type SessionFragmentFragment = { __typename?: 'AnswerSession', id: string, token: string, comment?: string | null, answers: Array<{ __typename?: 'Answer', value?: string | null, question: { __typename?: 'Question', id: string } }>, event: { __typename?: 'Event', id: string, name: string, raffle?: { __typename?: 'Raffle', id: string, winnersDetermined: boolean, endDate: any, prices: Array<{ __typename?: 'RafflePrice', id: string, name: string }> } | null, eventType: { __typename?: 'EventType', id: string, name: string, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: boolean | null, type: { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any }, nextCondition?: { __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string } | null }> } } } };

export type QuestionnaireFragment = { __typename?: 'Questionnaire', id: string, name: string, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: boolean | null, type: { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any }, nextCondition?: { __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string } | null }> };

export type QuestionFragment = { __typename?: 'Question', id: string, text: string, required?: boolean | null, type: { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any }, nextCondition?: { __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string } | null };

export type QuestionTypeFragment = { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any };

export type StoreAnswerMutationVariables = Exact<{
  token: Scalars['String'];
  questionId: Scalars['ID'];
  answer: Scalars['String'];
}>;


export type StoreAnswerMutation = { __typename?: 'Mutation', storeAnswer?: boolean | null };

export type StartSessionMutationVariables = Exact<{
  tan: Scalars['String'];
}>;


export type StartSessionMutation = { __typename?: 'Mutation', session: { __typename?: 'AnswerSession', id: string, token: string, comment?: string | null, answers: Array<{ __typename?: 'Answer', value?: string | null, question: { __typename?: 'Question', id: string } }>, event: { __typename?: 'Event', id: string, name: string, raffle?: { __typename?: 'Raffle', id: string, winnersDetermined: boolean, endDate: any, prices: Array<{ __typename?: 'RafflePrice', id: string, name: string }> } | null, eventType: { __typename?: 'EventType', id: string, name: string, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: boolean | null, type: { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any }, nextCondition?: { __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string } | null }> } } } } };

export type EndSessionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type EndSessionMutation = { __typename?: 'Mutation', endSession?: boolean | null };

export type StoreCommentMutationVariables = Exact<{
  token: Scalars['String'];
  comment: Scalars['String'];
}>;


export type StoreCommentMutation = { __typename?: 'Mutation', storeComment?: boolean | null };

export type EnterRaffleMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['String'];
}>;


export type EnterRaffleMutation = { __typename?: 'Mutation', enterRaffle?: string | null };

export type LoadSessionQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type LoadSessionQuery = { __typename?: 'Query', session?: { __typename?: 'AnswerSession', id: string, token: string, comment?: string | null, answers: Array<{ __typename?: 'Answer', value?: string | null, question: { __typename?: 'Question', id: string } }>, event: { __typename?: 'Event', id: string, name: string, raffle?: { __typename?: 'Raffle', id: string, winnersDetermined: boolean, endDate: any, prices: Array<{ __typename?: 'RafflePrice', id: string, name: string }> } | null, eventType: { __typename?: 'EventType', id: string, name: string, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: boolean | null, type: { __typename?: 'QuestionType', id: string, style: QuestionStyle, options: any }, nextCondition?: { __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string } | null }> } } } } | null };

export const QuestionTypeFragmentDoc = gql`
    fragment QuestionType on QuestionType {
  id
  style
  options
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  text
  required
  type {
    ...QuestionType
  }
  nextCondition {
    nextQuestion
    condition
  }
}
    ${QuestionTypeFragmentDoc}`;
export const QuestionnaireFragmentDoc = gql`
    fragment Questionnaire on Questionnaire {
  id
  name
  questions {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export const SessionFragmentFragmentDoc = gql`
    fragment SessionFragment on AnswerSession {
  id
  token
  answers {
    question {
      id
    }
    value
  }
  comment
  event {
    id
    name
    raffle {
      id
      winnersDetermined
      endDate
      prices {
        id
        name
      }
    }
    eventType {
      id
      name
      questionnaire {
        ...Questionnaire
      }
    }
  }
}
    ${QuestionnaireFragmentDoc}`;
export const StoreAnswerDocument = gql`
    mutation StoreAnswer($token: String!, $questionId: ID!, $answer: String!) {
  storeAnswer(token: $token, questionId: $questionId, answer: $answer)
}
    `;
export type StoreAnswerMutationFn = Apollo.MutationFunction<StoreAnswerMutation, StoreAnswerMutationVariables>;

/**
 * __useStoreAnswerMutation__
 *
 * To run a mutation, you first call `useStoreAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAnswerMutation, { data, loading, error }] = useStoreAnswerMutation({
 *   variables: {
 *      token: // value for 'token'
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useStoreAnswerMutation(baseOptions?: Apollo.MutationHookOptions<StoreAnswerMutation, StoreAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreAnswerMutation, StoreAnswerMutationVariables>(StoreAnswerDocument, options);
      }
export type StoreAnswerMutationHookResult = ReturnType<typeof useStoreAnswerMutation>;
export type StoreAnswerMutationResult = Apollo.MutationResult<StoreAnswerMutation>;
export type StoreAnswerMutationOptions = Apollo.BaseMutationOptions<StoreAnswerMutation, StoreAnswerMutationVariables>;
export const StartSessionDocument = gql`
    mutation StartSession($tan: String!) {
  session: startAnswerSessionWithAccessCode(tan: $tan) {
    ...SessionFragment
  }
}
    ${SessionFragmentFragmentDoc}`;
export type StartSessionMutationFn = Apollo.MutationFunction<StartSessionMutation, StartSessionMutationVariables>;

/**
 * __useStartSessionMutation__
 *
 * To run a mutation, you first call `useStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSessionMutation, { data, loading, error }] = useStartSessionMutation({
 *   variables: {
 *      tan: // value for 'tan'
 *   },
 * });
 */
export function useStartSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartSessionMutation, StartSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSessionMutation, StartSessionMutationVariables>(StartSessionDocument, options);
      }
export type StartSessionMutationHookResult = ReturnType<typeof useStartSessionMutation>;
export type StartSessionMutationResult = Apollo.MutationResult<StartSessionMutation>;
export type StartSessionMutationOptions = Apollo.BaseMutationOptions<StartSessionMutation, StartSessionMutationVariables>;
export const EndSessionDocument = gql`
    mutation EndSession($token: String!) {
  endSession(token: $token)
}
    `;
export type EndSessionMutationFn = Apollo.MutationFunction<EndSessionMutation, EndSessionMutationVariables>;

/**
 * __useEndSessionMutation__
 *
 * To run a mutation, you first call `useEndSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSessionMutation, { data, loading, error }] = useEndSessionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEndSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndSessionMutation, EndSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndSessionMutation, EndSessionMutationVariables>(EndSessionDocument, options);
      }
export type EndSessionMutationHookResult = ReturnType<typeof useEndSessionMutation>;
export type EndSessionMutationResult = Apollo.MutationResult<EndSessionMutation>;
export type EndSessionMutationOptions = Apollo.BaseMutationOptions<EndSessionMutation, EndSessionMutationVariables>;
export const StoreCommentDocument = gql`
    mutation StoreComment($token: String!, $comment: String!) {
  storeComment(token: $token, comment: $comment)
}
    `;
export type StoreCommentMutationFn = Apollo.MutationFunction<StoreCommentMutation, StoreCommentMutationVariables>;

/**
 * __useStoreCommentMutation__
 *
 * To run a mutation, you first call `useStoreCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeCommentMutation, { data, loading, error }] = useStoreCommentMutation({
 *   variables: {
 *      token: // value for 'token'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useStoreCommentMutation(baseOptions?: Apollo.MutationHookOptions<StoreCommentMutation, StoreCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreCommentMutation, StoreCommentMutationVariables>(StoreCommentDocument, options);
      }
export type StoreCommentMutationHookResult = ReturnType<typeof useStoreCommentMutation>;
export type StoreCommentMutationResult = Apollo.MutationResult<StoreCommentMutation>;
export type StoreCommentMutationOptions = Apollo.BaseMutationOptions<StoreCommentMutation, StoreCommentMutationVariables>;
export const EnterRaffleDocument = gql`
    mutation EnterRaffle($token: String!, $email: String!) {
  enterRaffle(token: $token, email: $email)
}
    `;
export type EnterRaffleMutationFn = Apollo.MutationFunction<EnterRaffleMutation, EnterRaffleMutationVariables>;

/**
 * __useEnterRaffleMutation__
 *
 * To run a mutation, you first call `useEnterRaffleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterRaffleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterRaffleMutation, { data, loading, error }] = useEnterRaffleMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEnterRaffleMutation(baseOptions?: Apollo.MutationHookOptions<EnterRaffleMutation, EnterRaffleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterRaffleMutation, EnterRaffleMutationVariables>(EnterRaffleDocument, options);
      }
export type EnterRaffleMutationHookResult = ReturnType<typeof useEnterRaffleMutation>;
export type EnterRaffleMutationResult = Apollo.MutationResult<EnterRaffleMutation>;
export type EnterRaffleMutationOptions = Apollo.BaseMutationOptions<EnterRaffleMutation, EnterRaffleMutationVariables>;
export const LoadSessionDocument = gql`
    query LoadSession($token: String!) {
  session(token: $token) {
    ...SessionFragment
  }
}
    ${SessionFragmentFragmentDoc}`;

/**
 * __useLoadSessionQuery__
 *
 * To run a query within a React component, call `useLoadSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSessionQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoadSessionQuery(baseOptions: Apollo.QueryHookOptions<LoadSessionQuery, LoadSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSessionQuery, LoadSessionQueryVariables>(LoadSessionDocument, options);
      }
export function useLoadSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSessionQuery, LoadSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSessionQuery, LoadSessionQueryVariables>(LoadSessionDocument, options);
        }
export type LoadSessionQueryHookResult = ReturnType<typeof useLoadSessionQuery>;
export type LoadSessionLazyQueryHookResult = ReturnType<typeof useLoadSessionLazyQuery>;
export type LoadSessionQueryResult = Apollo.QueryResult<LoadSessionQuery, LoadSessionQueryVariables>;