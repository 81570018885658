import { useState, useEffect, useMemo } from 'react';
import { client } from './apolloClient'

export function useRefetchableQuery(query, variables) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const refetch = useMemo(() => (async () => {
    setLoading(true)
    try {
      const { data } = await client
        .query({
          query,
          data: variables
        });
      setError(null)
      setData(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false);
    }
  }), [query, variables]);
  useEffect(() => {
    refetch()
    return () => { };
  }, [refetch])

  return { loading, data, error, refetch };
}


export const TAN_REGEX = /^[0-9A-Z]{6}$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;