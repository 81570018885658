import React, { useEffect, useState } from "react";
import { Button, Input } from "semantic-ui-react";
import useDebounce from "../hooks/useDebounce";
import { TAN_REGEX } from "../utils";

export default function EnterTan({
  onTanEntered,
}: {
  onTanEntered: (tan: string) => void;
}) {
  const [input, setInput] = useState("");
  const tan = useDebounce(input);

  useEffect(() => {
    if (TAN_REGEX.test(tan)) onTanEntered(tan);
  }, [tan, onTanEntered]);

  return (
    <div className="flex w-full">
      <Input
        className="flex-grow mr-2"
        size="large"
        fluid
        placeholder="Transaktionsnummer"
        onChange={(e) => setInput(e.target.value)}
      />
      <Button onClick={() => onTanEntered(tan)}>Bestätigen</Button>
    </div>
  );
}
