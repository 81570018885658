import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { client } from "../apolloClient";
import {
  QuestionFragment,
  QuestionTypeFragment,
  StoreAnswerDocument,
  StoreAnswerMutation,
  StoreAnswerMutationVariables,
} from "../generated/graphql";
import { useSession } from "./session";

export const answersState = atom<{ [id: string]: string }>({
  key: "answers",
  default: {},
});

let answeredQuestions: string[] = [];

function usePersistAnswer() {
  return async function persistAnswer(questionId: string, answer: string) {
    await client.mutate<StoreAnswerMutation, StoreAnswerMutationVariables>({
      mutation: StoreAnswerDocument,
      variables: {
        token: localStorage.getItem("token")!,
        questionId,
        answer,
      },
    });
  };
}

export function useAnswers() {
  const value = useRecoilValue(answersState);
  return value;
}

export function clearStores() {
  answeredQuestions = [];
}

export function useIsValid(questionId: string) {
  const answers = useAnswers();

  return Object.keys(answers).indexOf(questionId) >= 0;
}

export function isAnswered(questionId: string) {
  return answeredQuestions.indexOf(questionId) >= 0;
}

export function useAnswer(question: QuestionFragment) {
  const session = useSession();
  const answers = useAnswers();
  const setAnswerState = useSetRecoilState(answersState);
  const persistAnswer = usePersistAnswer();

  const loadedAnswer = useMemo(() => {
    const answer = session?.answers.filter(
      (answer) => answer.question.id === question.id
    )[0];
    return answer ? answer.value : answers[question.id] ?? null;
  }, [answers, session, question]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setUserChanged(false);
      setPersisting(false);
      setValid(isAnswerValid(loadedAnswer, question.type));
      setCurrentInput(loadedAnswer);
      setPrevQuestionId(question.id);
    }
    return () => {
      ignore = true;
    };
  }, [question]);

  const [prevQuestionId, setPrevQuestionId] = useState(question.id);
  const [currentInput, setCurrentInput] = useState(loadedAnswer);
  const [userChanged, setUserChanged] = useState(false);
  const [persisting, setPersisting] = useState(false);
  const [valid, setValid] = useState(
    isAnswerValid(currentInput, question.type)
  );

  const debouncedInput = currentInput; //useDebounce(currentInput, 500);
  useEffect(() => {
    let discard = false;
    if (
      isAnswerValid(debouncedInput, question.type) &&
      userChanged &&
      debouncedInput &&
      prevQuestionId === question.id
    ) {
      setPersisting(true);
      persistAnswer(question.id, debouncedInput)
        .catch((e) => {
          // Sentry
          console.log(e);
        })
        .finally(() => {
          if (!discard) setPersisting(false);
        });
    }
    return () => {
      discard = true;
    };
  }, [debouncedInput, question]);
  useLayoutEffect(() => {
    const v = isAnswerValid(currentInput, question.type);
    setValid(v);
    const i = answeredQuestions.indexOf(question.id);
    if (v && i < 0) answeredQuestions.push(question.id);
    if (!v && i >= 0) answeredQuestions.splice(i, 1);
  }, [currentInput, question]);

  /* return [currentInput, input => {
        setUserChanged(true);
        setCurrentInput(input);
        if (answeredQuestions.indexOf(question.id) < 0) answeredQuestions.push(question.id);
    }, persisting] */
  return {
    answer: currentInput,
    setAnswer: (input: string) => {
      setUserChanged(true);
      setCurrentInput(input);
      setAnswerState((prevAnswers) => ({
        ...prevAnswers,
        [question.id]: input,
      }));
    },
    persisting,
    dirty: userChanged,
    valid: valid,
  };
}

function isAnswerValid(
  answer: string | undefined | null,
  questionType: QuestionTypeFragment
) {
  const opt = questionType.options;
  switch (questionType.style) {
    case "RADIO":
    case "DROPDOWN":
      if (!answer) return false;
      if (opt.multiple === true)
        return !!opt.values.find((v: string) => answer.includes(v));
      return opt.values.indexOf(answer) >= 0;
    case "INT":
      if (!answer) return false;
      let n = parseInt(answer);
      return (
        (typeof opt.min === "undefined" || n >= opt.min) &&
        (typeof opt.max === "undefined" || n <= opt.max)
      );
    default:
      console.log(questionType);
  }
  return true;
}
