import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

const httpLink = new HttpLink({
  uri: (process.env.REACT_APP_API_URL ?? "http://localhost:4000") + "/graphql",
});

export const client = new ApolloClient({
  link: httpLink,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  cache: new InMemoryCache(),
});
