import { useState } from "react";
import { Button, Segment } from "semantic-ui-react";

export const LumdatalInit: React.FC<{ onAccepted: () => void }> = ({
  onAccepted,
}) => {
  const [accepted, setAccepted] = useState(false);
  return accepted ? null : (
    <Segment>
      <div className="space-y-2 mb-4">
        <h4 className="text-lg font-bold">Information zum Datenschutz</h4>

        <p>
          <strong>1.)</strong> Diese Umfrage dient dem Zweck, den Ist-Zustand
          Ihrer Feuerwehr zu ermitteln, um der Leitung und Führung der Feuerwehr
          die Gelegenheit zu geben, auf aktuelle Sachverhalte einzuwirken, sowie
          mittel- und langfristig die Entwicklung der Feuerwehr zu planen.
        </p>

        <p>
          <strong>2.)</strong> Die Auswertung der Daten erfolgt auf Ebene der
          Orts-/Stadtteilfeuerwehren, der Gesamtfeuerwehr oder auf Ebene
          einzelner Kohorten (z.B. Altersgruppen). Eine Auswertung von
          Einzelantworten wird nicht durchgeführt, ebenso wird die Auswertung
          von zu kleinen Kohorten, die Hinweis auf Einzelantworten geben
          könnten, nicht durchgeführt. Hierdurch wird der Schutz ihrer
          individuellen Antworten und Ihre Anonymität gewährleistet.
        </p>

        <p>
          <strong>3.) </strong>Eine Zuordnung zwischen Ihrer eben eingegebenen
          Teilnahmenummer und Ihrer Person ist technisch ausgeschlossen und
          findet nicht statt.
        </p>

        <p>
          <strong>4.)</strong> Die Daten werden digital erfasst. Nach
          Bearbeitung der Befragung werden die Daten dieser Umfrage offline in
          einem Sicherheitsschrank als Datenträger bis zum Ablauf der
          Aufbewahrungsfrist gelagert. Diese beträgt derzeit 6 Jahre und endet
          am 31.12. des sechsten Folgejahres der Befragung.
        </p>

        <p>
          <strong>5.) </strong>Ihre Teilnahme an der Umfrage ist freiwillig. Sie
          können die Befragung hier beenden. Sofern Sie fortfahren, willigen Sie
          in die Erfassung, Verarbeitung und Auswertung Ihrer Daten im oben
          genannten Umfang ein. Sie müssen Fragen nicht beantworten, wenn Sie
          dies nicht möchten. Sie können Fragen überspringen und die Umfrage
          jederzeit beenden.{" "}
        </p>

        <p>
          <strong>Verarbeiter Ihrer Daten ist </strong>
          <br />
          Dr. Ruhrmann und Kollegen UG
          <br />
          Hauffstraße 39, 35398 Gießen
          <br />
          Vertreten durch den Geschäftsführer Martin Lutz
        </p>

        <p>
          <strong>Datenschutzbeauftragte ist</strong>
          <br />
          Dr. Sophie Ruhrmann
          <br />
          datenschutz@ruhrmann-kollegen.de
          <br />
          Dr. Ruhrmann und Kollegen UG
          <br />
          Hauffstraße 39, 35398 Gießen
        </p>
      </div>
      <Button
        positive
        onClick={() => {
          setAccepted(true);
          onAccepted();
        }}
      >
        Akzeptieren und starten
      </Button>
    </Segment>
  );
};
